import axios from 'axios'
import * as actions from "./articleSlice"
import getApiUri from '../../helper/getApiUri'

const apiUrl =  getApiUri() + "/articles/"

export const getArticles = async (dispatch) => {
    dispatch(actions.getArticlesStart())
    try {
        const {data} = await axios.get(apiUrl)
        dispatch(actions.getArticlesSuccess(data.data))
        return true
    } catch (error) {
        dispatch(actions.getArticlesFailure())
        return false
    }
}