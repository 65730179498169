import { Card, CardBody, Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react"
import { NavLink } from 'react-router-dom'
import { useState } from 'react'

export default function ArticlePreview({ article }) {
    const [isHovering, setIsHovering] = useState(false)

    return (
        <NavLink to={"/help/" + article._id}>
            <Card h={'fit-content'} p={3} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)} style={{ backgroundColor: isHovering ? 'rgb(66, 153, 225)' : null }}>
                <CardBody>
                    <Heading size='md' mb={'1rem'}>{article.title}</Heading>
                    <UnorderedList>
                    {article.steps.map((step) => {
                        return (
                            <ListItem>{step}</ListItem>)
                    })}
                    </UnorderedList>
                </CardBody>
            </Card>
        </NavLink>
    )
}