import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getArticles } from "../redux/Article/articleCalls"
import ArticlePreview from "../components/ArticlePreview"
import { Flex, useColorModeValue, SimpleGrid, VStack } from "@chakra-ui/react"
import DebouncedInput from "../components/TanStackTable/DebouncedInput"
import getArticlesSearch from "../helper/fuzzyArticleSearch"

export default function Help() {
    const dispatch = useDispatch()
    const { articles } = useSelector((state) => state.articles)
    const [globalFilter, setGlobalFilter] = useState("")
    const [filteredArticles, setFilteredArticles] = useState([])

    useEffect(() => {
        getArticles(dispatch)
        setFilteredArticles(articles)
    }, [])

    useEffect(() => {
        if (globalFilter !== "") {
            getArticlesSearch(globalFilter, articles, setFilteredArticles)
        }else{
            setFilteredArticles(articles)
        }
    }, [globalFilter])

    return (
        <Flex
            minH={"100vh"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
            pt={"2rem"}
        >
            <VStack spacing={'2rem'} px={'2rem'}>
                <DebouncedInput
                    value={globalFilter ?? ""}
                    onChange={value => setGlobalFilter(String(value))}
                    placeholder="Search..."
                    width={"20vw"}
                    margin={"1rem"}
                />
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
                    {filteredArticles && filteredArticles.map((article) => {
                        if (!article.show) return null
                        return (
                            <ArticlePreview article={article} key={article._id} />
                        )
                    })}
                </SimpleGrid>
            </VStack>
        </Flex>
    )
}