import { Flex, useColorModeValue, VStack, useSteps, HStack, Button, Tooltip } from "@chakra-ui/react"
import MyStepper from '../../components/MyStepper'
import InitializePDF from "../../components/CreatePDF/InitializePDF"
import CustomizePDF from "../../components/CreatePDF/CustomizePDF"
import FinalizePDF from "../../components/CreatePDF/FinalizePDF"
import CompletePDF from "../../components/CreatePDF/CompletePDF"
import OrganizePDF from "../../components/CreatePDF/OrganizePDF"
import RevisePDF from "../../components/CreatePDF/RevisePDF"
import { FiArrowRight, FiArrowLeft } from 'react-icons/fi'
import { useDispatch, useSelector } from "react-redux"
import ItemizePDF from "../../components/CreatePDF/ItemizePDF"
import { toast } from "react-toastify"
import { fullReset } from "../../redux/CreateDoc/createDocSlice"
import { customAlphabet } from 'nanoid';
import { beginSuccess } from "../../redux/CreateDoc/createDocSlice"
import { useState, useEffect } from "react"
import { createDocument, getLastDocumentId } from "../../redux/Document/documentCalls"
import { RiDraftLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom"

export default function CreatePO() {
    const { contact } = useSelector((state) => state.createDoc)
    const { products } = useSelector((state) => state.createDoc)
    const { shipping_content } = useSelector((state) => state.createDoc)
    const { error } = useSelector((state) => state.documents)
    const { account_initial } = useSelector((state) => state.account)
    const { user_initials } = useSelector((state) => state.auth)
    const { doc_number } = useSelector((state) => state.createDoc)
    const { terms_content } = useSelector((state) => state.createDoc)
    const { shipping_content_incomplete } = useSelector((state) => state.createDoc)
    const { doc_type } = useSelector((state) => state.createDoc)
    const { date } = useSelector((state) => state.createDoc)
    const { contact_index } = useSelector((state) => state.createDoc)
    const { tables } = useSelector((state) => state.createDoc)
    const { product_indicies } = useSelector((state) => state.createDoc)
    const { content } = useSelector((state) => state.createDoc)
    const { total } = useSelector((state) => state.createDoc)
    const { discounts_enabled } = useSelector((state) => state.createDoc)
    const { global_discount_options } = useSelector((state) => state.createDoc)
    const { user_name } = useSelector((state) => state.auth)
    const { account_id } = useSelector((state) => state.account)
    const { document_number } = useSelector((state) => state.account)
    const [columns, setColumns] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // HR - provide name and description for steps to populate text in the stepper component
    const steps = [
        { title: 'Initialize', description: 'Select Vendor' },
        { title: 'Organize', description: 'Enter Additional Details' },
        { title: 'Itemize', description: 'Select Products' },
        { title: 'Revise', description: 'Rearrange Tables' },
        { title: 'Customize', description: 'Style PDF' },
        { title: 'Finalize', description: 'Review PDF' },
    ]

    // HR - state to track our current step
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,
    })

    useEffect(() => {
        const getLastIdFn = async () => { getLastDocumentId(account_id, dispatch) }
        getLastIdFn()
    }, [])

    useEffect(() => {
        // HR - if we don't have a document number, create one using a combination of the date, a random string, and the user's initials
        const now = new Date()
        const newDate = now.toISOString().split('T')[0]
        const newDocNumber = `${account_initial}${document_number + 1}${user_initials}`
        dispatch(beginSuccess({ date: newDate, doc_number: newDocNumber, version: 1, doc_type: 'PO' }))
    }, [document_number])

    function incrementStep() {
        switch (activeStep) {
            case 0:
                // HR - block the user from proceeding to the next step until a contact is selected
                if (Object.keys(contact).length === 0) {
                    toast.error("Please select a contact to continue!")
                } else if (shipping_content_incomplete) {
                    toast.error("Please specify shipping information!")
                } else {
                    setActiveStep(activeStep + 1)
                }
                break;

            case 1:
                // HR - block the user from proceeding to the next step until PO information is provided
                if (Object.keys(shipping_content).length > 0 && shipping_content.shipping_via !== "" && shipping_content.required_by !== "" && shipping_content.project_name !== "" && shipping_content.category !== "") {
                    setActiveStep(activeStep + 1)
                } else {
                    toast.error("Please fill out the form and save to continue!")
                }
                break;

            case 2:
                // HR - block the user from proceeding to the next step until a product is selected
                if (products.length > 0 && typeof terms_content.freight !== 'undefined') {
                    setActiveStep(activeStep + 1)
                } else if (products.length === 0) {
                    toast.error("Please select a product to continue!")
                } else {
                    toast.error("Please fill out the freight and save to continue!")
                }
                break;

            case 5:
                if (!error) {
                    setActiveStep(activeStep + 1)
                    dispatch(fullReset())
                } else {
                    console.log(error)
                }
                break;

            default:
                setActiveStep(activeStep + 1)
                break;
        }
    }

    function decrementStep() {
        if (activeStep !== 0) {
            setActiveStep(activeStep - 1)
        }
    }

    // HR - create new document from scratch or from existing document
    function handleCreateDraft() {
        const createPayload = {
            type: doc_type, active: 'active', is_complete: false, date_created: date, doc_number: doc_number, account_id: account_id, version: 1, contact: contact,
            contact_index: contact_index, products: products, tables: tables, product_indicies: product_indicies, content: content, total: total, created_by: user_name,
            shipping_content: shipping_content, terms_content: terms_content, discounts_enabled: discounts_enabled, global_discount_options: global_discount_options
        }
        createDocument(createPayload, dispatch)
        if (activeStep === 5) {
            navigate("/")
        }
    }

    return (
        <Flex
            minH={"100vh"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
            pt={10}
            pb={10}
        >
            <VStack spacing={10} w={'100%'} height={'100%'}>
                <MyStepper activeStep={activeStep} steps={steps} />
                <HStack spacing={["2rem", "7rem"]}>
                    {/* HR - dynamically display previous/next arrows depending on the current step */}
                    {activeStep > 0 && activeStep < 6 && <Button variant={"outline"} colorScheme={"blue"} onClick={decrementStep} leftIcon={<FiArrowLeft style={{ fontSize: "1.5rem" }} />}>Back</Button>}
                    {activeStep < 5 && <Tooltip label='Make sure to click any "Save" buttons first!'><Button variant={"solid"} colorScheme="orange" onClick={handleCreateDraft} rightIcon={<RiDraftLine style={{ fontSize: "1.5rem" }} />}>Save Draft</Button></Tooltip>}
                    {activeStep < 5 && <Button variant={"solid"} colorScheme="blue" onClick={incrementStep} rightIcon={<FiArrowRight style={{ fontSize: "1.5rem" }} />}>Next</Button>}
                </HStack>
                {/* HR - dyanamically display components depending on current step */}
                {activeStep === 0 && <InitializePDF type="PO" mode="create" />}
                {activeStep === 1 && <OrganizePDF type="PO" mode="create" />}
                {activeStep === 2 && <ItemizePDF type="PO" mode="create" columns={columns} setColumns={setColumns} />}
                {activeStep === 3 && <RevisePDF type="PO" mode="create" />}
                {activeStep === 4 && <CustomizePDF type="PO" mode="create" />}
                {activeStep === 5 && <FinalizePDF type="PO" incrementStep={incrementStep} mode="create" />}
                {activeStep === 6 && <CompletePDF type="PO" mode="create" />}
            </VStack>
        </Flex>
    )
}