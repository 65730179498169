import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { otherStyles } from "../../../styles/PDFStyles";

// HR - description IS showing
const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "center",
    },
    buffer: {
        width: "72.5%",
        fontSize: 10,
    },
    name: {
        width: "15%",
        fontSize: 10,
        paddingVertical: 7,
        paddingHorizontal: 2,
        borderLeft: '1px solid black',
        borderBottom: '1px solid black',
        borderTop: '1px solid black',
    },
    value: {
        width: "13.5%",
        fontSize: 10,
        paddingVertical: 8.2,
        paddingHorizontal: 2,
        borderRight: '1px solid black',
        borderBottom: '1px solid black',
        borderTop: '1px solid black',
    },
});

// HR - description is NOT showing
const styles2 = StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "center",
    },
    buffer: {
        width: "68%",
        fontSize: 10,
    },
    name: {
        width: "15%",
        fontSize: 10,
        paddingVertical: 7,
        paddingHorizontal: 2,
        borderLeft: '1px solid black',
        borderBottom: '1px solid black',
        borderTop: '1px solid black',
    },
    value: {
        width: "17%",
        fontSize: 10,
        paddingHorizontal: 2,
        paddingVertical: 8.2,
        borderRight: '1px solid black',
        borderBottom: '1px solid black',
        borderTop: '1px solid black',
    },
});

// HR this maps item data (or headers) into a row of text
const TableBottomRow = ({ name, value, show_description }) => {

    // HR - style for cells in row changes based on whether description is showing or not
    var whichStyle = styles
    if(!show_description){
        whichStyle = styles2
    }

    return (
        <View style={whichStyle.row}>
            <Text style={whichStyle.buffer}></Text>
            <Text style={whichStyle.name}><Text style={otherStyles["Grand Total"]}>{name}:</Text></Text>
            <Text style={whichStyle.value}>{' '} {value}</Text>
        </View>
    )

};

export default TableBottomRow;
