import { createSlice } from "@reduxjs/toolkit";

export const articleSlice = createSlice({
    name: "articles",
    initialState: {
        articles: [],
        getArticlesProgress: false,
        error: false,
    },
    reducers: {
        // get articles
        getArticlesStart: (state) => {
            state.getArticlesProgress = true
        },
        getArticlesSuccess: (state, action) => {
            state.error = false
            state.articles = action.payload
            state.getArticlesProgress = false
        },
        getArticlesFailure: (state) => {
            state.error = true
            state.getArticlesProgress = false
        },
    },
})

export const {
    getArticlesStart,
    getArticlesSuccess,
    getArticlesFailure,
} = articleSlice.actions

export default articleSlice.reducer