import {
    IconButton, Avatar, Box, CloseButton, Flex, HStack, VStack, Icon, useColorModeValue,
    Text, Drawer, DrawerContent, useDisclosure, Menu, MenuButton, MenuItem,
    MenuList, AccordionButton, Accordion, AccordionPanel, AccordionItem
} from "@chakra-ui/react"
import {
    FiSettings, FiMenu, FiEdit, FiChevronDown, FiBookOpen, FiUser, FiPackage, FiHelpCircle
} from "react-icons/fi"
import { FaBug, FaMoneyBillWave } from "react-icons/fa";
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import ColorModeToggle from "./ThemeButton"
import { useDispatch, useSelector } from "react-redux"
import { login } from "../redux/Auth/authCalls"
import { useGoogleLogin } from '@react-oauth/google';
import { store } from "../redux/store"
import { partialReset } from "../redux/CreateDoc/createDocSlice"
import { useEffect } from "react"
import { createPortal } from "../redux/Account/accountCalls";

// HR - signed-in drawer items
const UserItems = [
    { name: "Create", children: { "Create Quote": "/create/quote", "Create Purchase Order": "/create/purchaseorder" }, icon: FiEdit },
    { name: "History", children: { "Quote History": "/history/quote", "Purchase Order History": "/history/purchaseorder", "Invoice History": "/history/invoice", "Draft History": "/history/draft" }, icon: FiBookOpen },
    { name: "Account", children: { "Contacts": "/contacts", "Products": "/products" }, icon: FiUser },
    { name: "Resources", children: { "View Resources": "/resources" }, icon: FiPackage },
    { name: "Settings", children: { "Settings": "/settings" }, icon: FiSettings },
    { name: "Billing", statusCheck: true, children: {"Manage Subscription": null}, icon: FaMoneyBillWave },
    { name: "Report a Bug", children: { "Report a Bug": "/reportbug" }, icon: FaBug },
    { name: "Help", children: { "Help": "/help" }, icon: FiHelpCircle }
]

const SidebarContent = ({ onClose, ...rest }) => { // text inside sidebar/drawer
    const { account_id } = useSelector((state) => state.account)
    const { account_email } = useSelector((state) => state.account)
    const { status } = useSelector((state) => state.account.subscription)
    var LinkItems = UserItems

    // HR - get portal url and open in new tab
    async function handleManageBilling() {
        var url = await createPortal({ account_id })
        var win = window.open(url, '_blank')
    }

    // HR - this function redirects user to stripe checkout page, autofilling user email and changes based on which plan they select
    function handleSubscribe(frequency) {
        var planLink = 'https://buy.stripe.com/test_eVa6qgcDTgZJfkcdQQ'
        if (frequency === "yearly") {
            planLink = 'https://buy.stripe.com/test_00gdSI33jgZJ0pi9AB'
        }
        var url = planLink + '?prefilled_email=' + account_email
        var win = window.open(url, '_blank')
        win.focus()
    }

    return (
        <Box
            transition="3s ease"
            bg={useColorModeValue("white", "gray.900")}
            borderRight="1px"
            borderRightColor={useColorModeValue("gray.200", "gray.700")}
            w={"xs"}
            pos="fixed"
            h="full"
            overflow="auto"
            {...rest}
        >
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <NavLink to="/">
                    <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
                        RapidToolz
                    </Text>
                </NavLink>
                <CloseButton display="flex" onClick={onClose} />
            </Flex>
            <Accordion allowMultiple >
                {LinkItems.map(link => (
                    <AccordionItem key={link.name}>
                        <AccordionButton>
                            <NavItem icon={link.icon}>
                                {link.name}
                            </NavItem>
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            {Object.entries(link.children).map(([title, route]) => {
                                if (!link.statusCheck) {
                                    return (
                                        <NavLink key={title} to={route}>
                                            <NavItem>
                                                {title}
                                            </NavItem>
                                        </NavLink>
                                    )
                                } else if (status === "active") {
                                    // HR - manage billing button, opens in new tab when clicked
                                    return (
                                        <NavItem onClick={handleManageBilling} >Manage billing</NavItem>
                                    )
                                } else {
                                    return (
                                        <>
                                            <NavItem onClick={() => handleSubscribe("monthly")} >New Subscription (Monthly)</NavItem>
                                            <NavItem onClick={() => handleSubscribe("yearly")} >New Subscription (Yearly)</NavItem>
                                        </>
                                    )
                                }
                            })}
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </Box>
    )
}

const NavItem = ({ icon, children, ...rest }) => { // each of the sidebar buttons
    return (
        <Box
            style={{ textDecoration: "none" }}
            _focus={{ boxShadow: "none" }}
        >
            <Flex
                align="center"
                p="3"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    color: "cyan.400"
                }}
                {...rest}
            >
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: "cyan.400"
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Box>
    )
}

const MobileNav = ({ onOpen, ...rest }) => {
    const dispatch = useDispatch()
    const { user_name } = useSelector((state) => state.auth)
    const { picture } = useSelector((state) => state.auth)
    const navigate = useNavigate()
    const location = useLocation()

    const googleLogin = useGoogleLogin({
        onSuccess: tokenResponse => {
            login(tokenResponse.access_token, dispatch)
            navigate("/")
        }
    })

    useEffect(() => {
        // HR - used to reset createDoc state if user leaves create pages, works *okay* for now but still persists for a little when switching between create quote and po
        if (location.pathname !== '/edit/purchaseorder' && location.pathname !== '/edit/quote' && location.pathname !== '/edit/invoice' && location.pathname !== '/edit/options' && location.pathname !== '/view/purchaseorder' && location.pathname !== '/view/quote' && location.pathname !== '/view/invoice') {
            if (location.pathname !== '/create/quote' || location.pathname !== '/create/purchaseorder') {
                dispatch(partialReset())
            }
        }
    }, [location])

    function handleLogout() {
        store.dispatch({ type: "RESET" })
        navigate('/')
    }

    return (
        <Flex
            ml={0}
            px={4}
            height="20"
            alignItems="center"
            bg={useColorModeValue("white", "gray.900")}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue("gray.200", "gray.700")}
            justifyContent="space-between"
            {...rest}
        >
            {/* this is the hamburger icon */}
            <IconButton
                display="flex"
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />
            <NavLink to="/" style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, 0%)' }}>
                <Text
                    display="flex"
                    fontSize="2xl"
                    fontFamily="monospace"
                    fontWeight="bold"
                >
                    RapidToolz
                </Text>
            </NavLink>
            <HStack spacing={6}>
                <ColorModeToggle />
                <Flex alignItems={"center"}>
                    <Menu>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _focus={{ boxShadow: "none" }}
                        >
                            <HStack>
                                <Avatar
                                    size={"sm"}
                                    src={picture}
                                    content="no-referrer"
                                />
                                <VStack
                                    display={{ base: "none", md: "flex" }}
                                    alignItems="flex-start"
                                    spacing="1px"
                                    ml="2"
                                >
                                    <Text fontSize="sm">{user_name}</Text>
                                </VStack>
                                <Box display={{ base: "none", md: "flex" }}>
                                    <FiChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList>
                            <MenuItem as="button" onClick={handleLogout}>Logout</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    )
}

const SidebarWithHeader = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Box>
            <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                size={'xs'}
            >
                <DrawerContent>
                    <SidebarContent onClose={onClose} />
                </DrawerContent>
            </Drawer>
            <MobileNav onOpen={onOpen} />
        </Box>
    )
}

export default SidebarWithHeader
