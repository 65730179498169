import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import TableRow from "./TableRow";
import { textStyles, otherStyles } from "../../../styles/PDFStyles";
import TableBottomRow from "./TableBottomRow";

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginVertical: 10
    },
    header: {
        fontWeight: 900,
        fontSize: 12,
        fontFamily: 'Times-Bold',
    },
    content: {
        fontSize: 10,
    }
});


const ItemsTable = ({ data, header, type, discounts_enabled, tableTotal, totalType, isLastTable, grandTotal, currency, show_description, freight, show_freight }) => {

    // HR - created formatter to transform 2500 -> $2,500.00, or chosen currency (supported = USD, GBP, EUR)
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
    })

    return (
        <View style={styles.tableContainer}>
            {/*<TableHeader />*/}
            {/* HR - one row for headers, one row for ALL data, which is mapped in TableRow */}
            <TableRow style={styles.header} items={header.items} type={type} discounts_enabled={discounts_enabled} currency={currency} show_description={show_description} />
            <TableRow style={styles.content} items={data} type={type} discounts_enabled={discounts_enabled} currency={currency} show_description={show_description} />
            {/* HR - if we are doing a grand total and this is the last table, add grand total anfd freight text */}
            {/* These rows have special formatting to line with the line totals at the end of the table */}
            {(totalType === "grand" && isLastTable) &&
                <>
                    {/* HR - only show freight if opted for */}
                    {show_freight &&
                        <TableBottomRow name={"Freight"} value={formatter.format(Number(freight))} show_description={show_description} />
                    }
                    <TableBottomRow name={"Grand Total"} value={formatter.format(Number(grandTotal))} show_description={show_description} />
                </>
            }
            {/* HR - if we are doing itemized totals, add total text for this table */}
            {(totalType === "itemized" && isLastTable && show_freight) &&
                <>
                    <TableBottomRow name={"Freight"} value={formatter.format(Number(freight))} show_description={show_description} />
                </>
            }
            {totalType === "itemized" &&
                <TableBottomRow name={"Total"} value={formatter.format(Number(tableTotal))} show_description={show_description} />
            }
            {/* HR - if we are doing grand totals and this is NOT the last table, do NOT render total text */}
        </View>
    )
};

export default ItemsTable;
