import Fuse from "fuse.js"

export default function getArticlesSearch(globalFilter, articles, setFilteredArticles) {

    const options = {
        includeScore: true,
        keys: ['title', 'content', 'steps']
    }
    const fuse = new Fuse(articles, options)


    const results = fuse.search(globalFilter);
    const items = results.map((result) => result.item);
    setFilteredArticles(items)
}