import { Flex, Container, Heading, Text, useColorModeValue, useSteps, HStack, Stepper, Step, StepIndicator, StepStatus, StepIcon, StepNumber, Box, StepTitle, StepSeparator, Button, Image } from "@chakra-ui/react"
import { useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from "react-redux"
import "../styles/styles.css"

export default function Article() {
    const id = useParams().id
    const { articles } = useSelector((state) => state.articles)
    const thisArticle = articles.find((article) => {
        return article._id === id
    })
    const sectionRefs = useRef([])

    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: thisArticle.steps.length,
    })

    const handleStepClick = (index) => {
        setActiveStep(index)
        sectionRefs.current[index].scrollIntoView({ behavior: 'smooth' })
    }


    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const index = sectionRefs.current.indexOf(entry.target)
                        if (index !== -1) {
                            setActiveStep(index)
                        }
                    }
                })
            },
            { threshold: 0.2 } // 20% of the section visible
        )

        // Observe all section refs
        sectionRefs.current.forEach((ref) => {
            if (ref) {
                observer.observe(ref)
            }
        })

        // Cleanup observer on unmount
        return () => {
            observer.disconnect()
        }

    }, [sectionRefs, setActiveStep])

    return (
        <Flex
            minH={"100vh"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
            pt={10}
            pb={10}
        >
            <HStack spacing={'7vw'} align="flex-start" p={'2rem'}>
                <Box
                    sx={{
                        position: "sticky", // Makes it sticky
                        top: "20vh", // Distance from the top of the viewport
                    }}
                    left={['10vw', '10vw', '5vw', '10vw', '15vw']}
                    flexShrink={0}
                    display={{ base: "none", md: "block" }}
                >
                    <Stepper index={activeStep} orientation='vertical' gap='100' px={'2rem'}>
                        {thisArticle.steps.map((step, index) => (
                            <Button variant={"link"} key={index} onClick={() => handleStepClick(index)}>
                                <Step>
                                    <StepIndicator>
                                        <StepStatus
                                            complete={<StepIcon />}
                                            incomplete={<StepNumber />}
                                            active={<StepNumber />}
                                        />
                                    </StepIndicator>

                                    <Box flexShrink='0'>
                                        <StepTitle>{step}</StepTitle>
                                    </Box>

                                    <StepSeparator />
                                </Step>
                            </Button>
                        ))}
                    </Stepper>
                </Box>
                <Container>
                    <Heading mb={'2rem'}>{thisArticle.title}</Heading>
                    {thisArticle.steps.map((step, index) => {
                        return (
                            <Box py={'1rem'}>
                                <Text key={index} fontSize={'xl'} fontWeight={'bold'} m={10} ref={(el) => (sectionRefs.current[index] = el)}>{step}</Text>
                                {thisArticle.media[index] !== null && <Image src={require("../gifs/" + thisArticle.media[index])} alt={step} mb={'2rem'}/>}
                                <Text className="display-linebreak">{thisArticle.content[index]}</Text>
                            </Box>
                        )
                    })}
                </Container>
            </HStack>

        </Flex>
    )
}