import tablesHelperPDF from "./tableHelperPDF";
import { current } from "@reduxjs/toolkit";

// HR - used to calculcate product totals for POs
function POCalculcator(product) {
    return parseFloat(product.cost * product.quantity)
}

// HR - used to calculcate product totals for Quotes & Invoices
function QuoteCalculator(product) {
    return parseFloat(product.list_price * product.quantity)
}

// HR - used to calculcate product totals for Quotes & Invoices
function QuoteDiscountedCalculator(product) {
    return parseFloat(product.calculated_price * product.quantity)
}

// HR - used to find the calculated_price for a Quote/Invoice
function CalculateMyPrice(product) {
    var priceBasedOn = product.list_price
    var prodDiscount = parseFloat(product.discount_options.percentage) / 100.0;
    if (product.discount_options.use_cost) { // HR - if we are using cost, reset priceBasedOn variable
        priceBasedOn = product.cost
    }

    if (product.discount_options.use_markup) { // HR - markup price calculation
        return parseFloat((priceBasedOn / (1 - prodDiscount)))
    } else { // HR - discount price calculation
        return parseFloat((priceBasedOn * (1 - prodDiscount)))
    }
}

// HR - used to find a discount for a Quote/Invoice given the calculated_price
function DiscountCalculator(product) {
    return parseFloat((1 - (product.calculated_price / (product.quantity * product.list_price))) * 100)
}

// HR - this function is used for total calculation, grand or itemized
function TableTotalCalculator(products, tables, freight) {
    const tableKeys = Object.keys(tables)
    var returnTotals = {}
    var grandTotal = 0
    // HR - loop through table object arrays
    tableKeys.forEach((tableKey, index) => {
        returnTotals[tableKey] = 0
        tables[tableKey].forEach(item => {
            // HR - for each item in array, grab product_id, find associated product object and push to new returnTable dictionary
            var itemID = item.split(":::")[1].replace(/[{()}]/g, '')
            var productTotal = products.filter((product) => product.product_id === itemID)[0]?.total
            returnTotals[tableKey] += Number(productTotal)
        })
        // HR - add the freight to the total of the last table, and thus the grand total as well
        if(index === tableKeys.length - 1 ){
            returnTotals[tableKey] += Number(freight)
        }
        grandTotal += returnTotals[tableKey]
    })
    return { "itemized": returnTotals, "grand": grandTotal }
}

export { POCalculcator, QuoteCalculator, QuoteDiscountedCalculator, DiscountCalculator, CalculateMyPrice, TableTotalCalculator }